<template>
  <div style="background-color: #EBEED7;">
   <section>
      <article
        style="padding-top: 160px;">
      
        <div class="container" style="margin: 50px auto; text-align: left">
          <div class="article_intro">
            <p class="locus_word_style">
                    <small>KINAWATAKA WOMEN INITIATIVES</small>
                  </p>
            <h2 style="font-weight: 600; font-size: 2em">
              Donation for straw pressing machine
            </h2>
            <p style="">
                In 2010, Kinawataka Women Initiatives (KIWOI) was listed as an international vendor 
                for Coca cola waste straws which saw the organisation receive orders for its 
                products from Kenya, Tanzania, Germany and USA. In the same year 2010, KIWOI got an 
                order from Italy for 100,000 shopping bags. In 2011, KIWOI got an order from the 
                United States of America for 20,000 different items but all these could not be 
                supplied because of the major challenge in operations which is the lack of a straw 
                pressing machine.
            </p>
            <p style="">
                Due to lack of a straw pressing machine, straws are hand pressed and this delays 
                production, which implies inability to take large orders and meet production 
                requirements. Manually, 600straws can be pressed in a day, implies producing 1 
                shopping bag in 5 days. With the machine, 3,000straws can be pressed in a minute, 
                with increased production; KIWOI can take on larger orders.
            </p>
            <p>
                We are seeking to raise USD 20000 to help us acquire this straw pressing machine 
                that was designed by a Chinese manufacturer.
            </p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="mb-5">
        <div class="container">
            
        </div>
      </article>
    </section>
    
  </div>
</template>

<script>

export default {

  data() {
    return {
        
    };
  },

  methods: {
      
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .locus_word_style {
        font-size: 0.5em; font-weight: 300;
        word-spacing: 8px;
        letter-spacing: 5px;
    }
    .article_intro {
        width: 80%;
    }

    /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) { }

    /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { }

    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {
        .article_intro {
            width: 100%;
        }
    }

    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
        .article_intro {
            width: 100%;
        }
    }
</style>
